import {connect} from "react-redux"
import React, {useEffect, useReducer, useState} from 'react';
import edjsParser from './components/editjs_html'
import LocalTimeAgo from "./components/local_time_ago";
import './Article.scss'
import {LoadingOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import Tools from "./tools";

import LogoImg from './images/logo.png';
import AndroidImg from './images/android.png';
import IosImg from './images/ios.png';
import CloseImg from './images/close.png';
import ArrowImg from './images/arrow.png';

const initState = {
    content: null,
    loading: false,
    article: null
}

function treeReducer(state, action) {
    if (action.type === 'SETTING')
        return {...state, loading: action.loading}
    else if (action.type === 'DATA')
        return {...state, content: action.content, article: action.article}
    else
        return state;
}

function Article(props = {}) {
    const iosUrl = 'itms-apps://itunes.apple.com/app/id1554030035';
    const androidUrl = "https://acupoint3d.oss-cn-shenzhen.aliyuncs.com/apk/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D%20latest.apk";
    const {column_title, article_title} = useParams();
    const [showDownload, setShowDownload] = useState(true);
    const [isIos, setIsIos] = useState(false);
    const [showMask, setShowMask] = useState(false);
    const [isWechat, setIsWechat] = useState(false);

    const [state, dispatch] = useReducer(treeReducer, initState, (state) => state);

    useEffect(() => {
        setTimeout(() => {
            document.title = article_title;
        }, 200);
        if (window.navigator.userAgent.includes("zj-app"))
            setShowDownload(false);
        setIsIos(Tools.isIos());
        setIsWechat(Tools.isWeChat());
    }, []);

    useEffect(() => {
        dispatch({type: 'SETTING', loading: true})
        props.jsonRequest.get(`/api/cms/browser/${column_title}/${article_title}`)
            .then(response => {
                dispatch({type: 'SETTING', loading: false})
                return response.json()
            })
            .then(({data}) => {
                let editorJSContent = data?.data?.editorjs
                dispatch({type: 'DATA', content: editorJSContent, article: data})
            })
    }, [props.jsonRequest]);

    function download() {
        let url = androidUrl;
        if (isIos) url = iosUrl;
        else if (isWechat) {
            // 展示mask
            setShowMask(true);
            return;
        }
        window.location.href = url;
    }

    return <>
        <div className="article-wrap">
            {
                showDownload ? <div className="share-wrap" onClick={download}>
                    <div className="share-wrap-content">
                        <div className="description">
                            <img src={LogoImg} alt="知源经络穴位"/>
                            <div>
                                <h3>知源经络穴位</h3>
                                <p>经络穴位针灸的立体活地图</p>
                            </div>
                        </div>
                        <div className="action">
                            <img src={isIos ? IosImg : AndroidImg} alt="点击下载"/>&ensp;
                            <p>点击下载</p>
                        </div>
                    </div>
                </div> : null
            }
            {state.content && <div className="article-wrap-content" style={{paddingTop: showDownload ? "70px" : "15px"}}>
                <div className="title">{state.article.title || "文章标题"}</div>
                <div dangerouslySetInnerHTML={{__html: edjsParser.parse(state.content).join("")}}/>
                <div className="meta" style={{color: "#888", fontSize: 12, textAlign: "right"}}>最近更新：<LocalTimeAgo
                    date={new Date(state.content.time)}/></div>
            </div>
            }
        </div>
        <div className="loading" style={{display: state.loading ? "block" : "none"}}>
            <div>
                <LoadingOutlined className="loading-icon"/>
                <p>拼命加载中...</p>
            </div>
        </div>
        {
            showMask && <div className="mask">
                <div>
                    <img src={CloseImg} alt="关闭" onClick={() => setShowMask(false)} style={{width: "20px"}}/>
                    <h4>点击右上角[更多]，使用浏览器打开</h4>
                    <img src={ArrowImg} alt="浏览器打开"/>
                </div>
            </div>
        }
    </>;
}

const mapStateToProps = ({global}) => {
    return {
        jsonRequest: global.jsonRequest
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(Article)