import { useEffect, useRef, useState } from "react";
import { ReactReader, ReactReaderStyle } from "react-reader"
import { useLocation } from "react-router-dom";
import "./index.scss"
import Aa from "../images/aa.svg"
import useLocalStorageState from "use-local-storage-state";

function Reader(props) {
    const [epubLocation, setEpubLocation] = useLocalStorageState("process")
    const reader = useRef()
    const renditionRef = useRef()
    const tocRef = useRef()
    const [page, setPage] = useState('')
    const [chapter, setChapter] = useState('')
    const [fontSize, setFontSize] = useLocalStorageState("font-size")
    const [bgColor, setBgColor] = useLocalStorageState("bgColor", { defaultValue: "#ffffff" })
    const [fgColor, setFgColor] = useLocalStorageState("fgColor", { defaultValue: "#444444" })
    const [showTitle, setShowTitle] = useState(true);

    const bookName = props.match.params?.name

    useEffect(() => {
        setTimeout(() => {
            document.title = `知源读书`;
        }, 200);
        window.r = reader
    }, []);

    const locationChanged = (epubcifi) => {
        // epubcifi is a internal string used by epubjs to point to a location in an epub. It looks like this: epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
        setEpubLocation(epubcifi)
        if (renditionRef.current && tocRef.current) {
            const { displayed, href } = renditionRef.current.location.start
            const chapter = tocRef.current.find((item) => item.href === href)
            setPage(`第 ${displayed.page} 页，共 ${displayed.total} 页`)
            setChapter(chapter ? `${chapter.label}` : '')
        }
    }

    const changeFontSize = (value) => {
      setFontSize(value)

      if (renditionRef.current)
        renditionRef.current.themes.fontSize(value)
    }

    const changeColor = (fg, bg) => {
        setBgColor(bg)
        setFgColor(fg)
    }

    const onInit = (rendition) => {
        window.rendition = rendition
        renditionRef.current = rendition
        rendition = reader.current.readerRef.current.book.rendition;
        rendition.themes.fontSize(fontSize)
        rendition.themes.override("lineHeight", 2)
        locationChanged(rendition)
    }

    return <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}
                // onClick={() => setShowTitle(!showTitle)}
    >
        <ReactReader
            ref={reader}
            className="epub-reader"
            loadingView={<div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>加载中...</div>}
            title={bookName}
            swipeable
            styles={{
                ...ReactReaderStyle,
                titleArea: {
                  ...ReactReaderStyle.titleArea,
                  top: showTitle ? "0" : "-50px",
                  left: 0,
                  right: 0,
                  color: fgColor,
                  backgroundColor: "#eeeeee7a",
                  padding: "15px 0",
                  fontWeight: "bold",
                  transition: "all .5s ease"
                },
                tocButton: {...ReactReaderStyle.tocButton, zIndex: 201},
                tocButtonBar: {...ReactReaderStyle.tocButtonBar, backgroundColor: fgColor},
                reader: {...ReactReaderStyle.reader, inset: "40px 15px 70px"},
                readerArea: {...ReactReaderStyle.readerArea, lineHeight: "15px", backgroundColor: bgColor, color: fgColor},
                tocArea: {...ReactReaderStyle.tocArea, color: fgColor},
                tocAreaButton: {...ReactReaderStyle.tocAreaButton, color: fgColor},
                arrow: {...ReactReaderStyle.arrow, color: fgColor, display: "none"}
            }}
            showToc={showTitle}
            location={epubLocation}
            locationChanged={locationChanged}
            url={`https://acupoint-resource.oss-cn-shenzhen.aliyuncs.com/book/${bookName}.epub`}
            getRendition={onInit}
            tocChanged={toc => tocRef.current = toc}
        />
        {/*<div style={{ position: 'absolute', top: 50, left: 30, textAlign: 'center', zIndex: 1, color: "#7e7e7e"}}>*/}
        {/*    {chapter}*/}
        {/*</div>*/}
        <div style={{
          position: 'absolute',
          width: "100%",
          bottom: 60,
          textAlign: 'center',
          color: "#7e7e7e",
          zIndex: 1
        }}>
            {page}
        </div>
        <div className="tool-bar" style={{ bottom: showTitle ? "0" : "-60px"}}>
            <div className="tool" onClick={() => changeColor("#444", "#fff")}>
                <div className="bg-color" style={{ backgroundColor: "#fff" }}></div>
            </div>
            <div className="tool" onClick={() => changeColor("#444", "#e7e0c6")}>
                <div className="bg-color" style={{ backgroundColor: "#e7e0c6" }}></div>
            </div>
            <div className="tool" onClick={() => changeColor("#444", "#CCEBCF")}>
                <div className="bg-color" style={{ backgroundColor: "#CCEBCF" }}></div>
            </div>
            <div className="tool font-down" onClick={() => changeFontSize("80%")}>
                <img src={Aa} />
            </div>
            <div className="tool font-normal" onClick={() => changeFontSize("100%")}>
                <img src={Aa} />
            </div>
            <div className="tool font-up" onClick={() => changeFontSize("120%")}>
                <img src={Aa} />
            </div>
        </div>
    </div>
}


export default Reader;